@charset "UTF-8";
a[href^="tel:"],
a[href^="mailto:"] {
  text-decoration: none; }
  a[href^="tel:"]:hover,
  a[href^="mailto:"]:hover {
    text-decoration: underline; }

ul.links {
  margin: 0;
  padding: 0; }
  ul.links li {
    display: inline;
    list-style: none;
    padding: 0 10px 0 0; }
  ul.links.inline {
    display: block; }

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .nav:before, .nav:after {
    display: table;
    content: " "; }
  .nav:after {
    clear: both; }
  .nav > li {
    position: relative;
    display: block; }
    .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: white; }
    .nav > li.disabled > a {
      color: #e5e6e6; }
      .nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
        color: #e5e6e6;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent; }
  .nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: white;
    border-color: #494a4c; }
  .nav .nav-divider {
    height: 1px;
    margin: 10px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .nav > li > a > img {
    max-width: none; }

.nav-tabs {
  border-bottom: 1px solid #ddd; }
  .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857;
      border: 1px solid transparent;
      border-radius: 8px 8px 0 0; }
      .nav-tabs > li > a:hover {
        border-color: white white #ddd; }
    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      color: #626366;
      cursor: default;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent; }

.nav-pills > li {
  float: left; }
  .nav-pills > li > a {
    border-radius: 8px; }
  .nav-pills > li + li {
    margin-left: 2px; }
  .nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #626366; }

.nav-stacked > li {
  float: none; }
  .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.nav-justified, .nav-tabs.nav-justified {
  width: 100%; }
  .nav-justified > li, .nav-tabs.nav-justified > li {
    float: none; }
    .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
      margin-bottom: 5px;
      text-align: center; }
  .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .nav-justified > li, .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0; }
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 8px; }
  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus,
  .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 8px 8px 0 0; }
    .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a,
    .nav-tabs-justified > .active > a:hover,
    .nav-tabs.nav-justified > .active > a:hover,
    .nav-tabs-justified > .active > a:focus,
    .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff; } }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  margin-bottom: .5em;
  margin-top: 0; }
  .breadcrumb ol {
    display: inline;
    margin: 0;
    padding: 0; }
    .breadcrumb ol li {
      list-style: none;
      display: inline; }
  .breadcrumb .breadcrumb-label {
    font-size: 1em;
    display: inline;
    padding-right: 10px; }
    .breadcrumb .breadcrumb-label::after {
      content: ':'; }

.breadcrumb-seperator {
  color: #636466;
  font-size: .9em;
  padding: 0 5px; }

.menu {
  margin: 0;
  padding: 0; }
  .menu li {
    list-style: none;
    display: block;
    position: relative; }
    .menu li:last-child {
      border-bottom: 0 none; }
    .menu li a {
      display: block;
      text-decoration: none;
      padding: 9px 15px;
      outline: 0;
      line-height: normal; }
  .menu::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .menu .views-row {
    margin-bottom: 0; }

.menu-item--highlight {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important; }
  .menu-item--highlight > a {
    background-color: #ff810f;
    color: white;
    padding: 10px 15px !important;
    border-radius: 2px;
    font-weight: 700 !important; }
  .menu-item--highlight:hover > a {
    border-radius: 2px 2px 0 0; }

.webks-menu-hover .expanded:hover > a {
  background-color: #626366;
  color: #fff !important; }

.webks-menu-hover .expanded:hover > .menu {
  display: block; }

.webks-menu-hover .expanded > .menu {
  display: none; }

.webks-menu-dropdown .menu .menu-item--right .menu {
  left: auto;
  right: 0; }
  .webks-menu-dropdown .menu .menu-item--right .menu .menu {
    left: auto;
    right: 100%; }

.webks-menu-dropdown .menu .menu {
  display: none;
  top: 100%;
  left: 0;
  position: absolute;
  z-index: 99;
  background: #626366;
  color: #fff;
  padding: 5px 0;
  width: 100%;
  min-width: 320px;
  max-width: 100%;
  margin-top: -5px; }
  .webks-menu-dropdown .menu .menu li {
    border-color: #565659; }
  .webks-menu-dropdown .menu .menu a {
    color: #fff; }
  .webks-menu-dropdown .menu .menu a:hover {
    background-color: #3c3c3c; }
  .webks-menu-dropdown .menu .menu .btn-expandable-menu-item--closer {
    background-color: #4e4f51; }
  .webks-menu-dropdown .menu .menu .menu {
    background-color: #4e4f51; }
    .webks-menu-dropdown .menu .menu .menu .btn-expandable-menu-item--closer {
      background-color: #444547; }
    .webks-menu-dropdown .menu .menu .menu .menu {
      background-color: #444547; }
      .webks-menu-dropdown .menu .menu .menu .menu .btn-expandable-menu-item--closer {
        background-color: #353637; }
      .webks-menu-dropdown .menu .menu .menu .menu .menu {
        background-color: #353637; }

.webks-menu-dropdown .menu .expanded.open {
  z-index: 9999; }
  .webks-menu-dropdown .menu .expanded.open > .menu {
    display: block; }

.webks-menu-dropdown .menu .expanded:not(.expandable-menu-item).open > a {
  background-color: #626366;
  color: #fff; }

.webks-menu-dropdown .menu .menu .menu, .webks-menu-dropdown--horizontal .menu .menu {
  left: 100%;
  top: 0;
  bottom: auto;
  right: auto; }

.expandable-menu-item > .btn-expandable-menu-item {
  width: 40px;
  height: 100%;
  max-height: 44px;
  display: block;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #626366;
  cursor: pointer;
  text-indent: -9000px; }
  .expandable-menu-item > .btn-expandable-menu-item--opener::before {
    font-family: "iconfont";
    content: ""; }
  .expandable-menu-item > .btn-expandable-menu-item--closer::before {
    font-family: "iconfont";
    content: ""; }
  .expandable-menu-item > .btn-expandable-menu-item::before {
    width: 40px;
    height: 40px;
    font-family: "iconfont";
    font-size: 18px;
    text-indent: 0;
    float: left;
    border-radius: 0;
    line-height: 40px; }

.expandable-menu-item > a {
  margin-right: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.expandable-menu-item > .menu {
  display: none; }

.expandable-menu-item.open > .menu {
  display: block; }

.region-navigation-bar {
  display: none; }
  @media (min-width: 768px) {
    .region-navigation-bar {
      display: block; } }

.navigation-bar-wrapper .menu-block-wrapper > .menu {
  display: flex;
  justify-content: center;
  text-align: center; }
  @media (min-width: 992px) {
    .navigation-bar-wrapper .menu-block-wrapper > .menu {
      margin-left: -8px;
      margin-right: -8px; }
      .navigation-bar-wrapper .menu-block-wrapper > .menu > li {
        float: left;
        border-bottom: 0 none; }
        .navigation-bar-wrapper .menu-block-wrapper > .menu > li.last {
          border-right: 0; }
        .navigation-bar-wrapper .menu-block-wrapper > .menu > li > a {
          padding-left: 8px;
          padding-right: 8px; } }
  .navigation-bar-wrapper .menu-block-wrapper > .menu > li {
    text-align: left; }
    .navigation-bar-wrapper .menu-block-wrapper > .menu > li > a {
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      white-space: nowrap;
      padding: 15px 30px; }

.not-front .navigation-bar-wrapper .depth-0 > a:not(.active):not(.active-trail) {
  color: #636466; }

.not-front .navigation-bar-wrapper .depth-0.expanded > a:not(.active):not(.active-trail):hover {
  color: #fff; }

@media (max-width: 991px) {
  body {
    padding-top: 76px !important; }
    body.multisite-wisloh {
      padding-top: 60px !important; } }

@media (max-width: 1169px) {
  .headroom {
    transform: translateY(0%);
    top: 0;
    display: block; } }

.headroom--pinned {
  transform: translateY(0%);
  top: 0;
  display: block; }

body:not(.webks-offcanvas-layer-opened) .headroom--unpinned {
  transform: translateY(-100%);
  margin-top: -1px; }

.drowl-headroom-wrapper {
  background: #fff;
  max-width: 100vw;
  border-bottom: 1px solid #d8d9da;
  width: 100% !important;
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  -webkit-transition: transform 300ms linear;
  -o-transition: transform 300ms linear;
  transition: transform 300ms linear; }
  @media (min-width: 992px) {
    .drowl-headroom-wrapper {
      display: none; } }
  @media (min-width: 992px) {
    .drowl-headroom-wrapper--invisible {
      display: block;
      transform: translateY(-100%);
      margin-top: -1px; } }
  .drowl-headroom-wrapper:not(.drowl-headroom-wrapper--invisible) {
    display: block; }
  .drowl-headroom-wrapper > .region-inner {
    max-width: 1170px;
    margin: 0 auto;
    padding: 5px 30px;
    display: table;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1169px) {
      .drowl-headroom-wrapper > .region-inner {
        padding-left: 15px;
        padding-right: 15px; } }
    .drowl-headroom-wrapper > .region-inner > .block {
      display: table-cell;
      vertical-align: top; }
    .drowl-headroom-wrapper > .region-inner .page-logo {
      max-width: 150px;
      padding: 15px 0;
      margin-right: 30px;
      margin-top: -12px;
      margin-bottom: -19px; }
  .drowl-headroom-wrapper .block-menu-block {
    float: left; }
  .drowl-headroom-wrapper .menu-block-wrapper > .menu {
    display: flex;
    justify-content: center;
    text-align: center; }
    @media (min-width: 992px) {
      .drowl-headroom-wrapper .menu-block-wrapper > .menu {
        margin-left: -8px;
        margin-right: -8px; }
        .drowl-headroom-wrapper .menu-block-wrapper > .menu > li {
          float: left;
          border-bottom: 0 none; }
          .drowl-headroom-wrapper .menu-block-wrapper > .menu > li.last {
            border-right: 0; }
          .drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a {
            padding-left: 8px;
            padding-right: 8px; } }
    .drowl-headroom-wrapper .menu-block-wrapper > .menu > li {
      text-align: left; }
      .drowl-headroom-wrapper .menu-block-wrapper > .menu > li > a {
        font-weight: 400;
        font-size: 16px;
        text-transform: uppercase;
        white-space: nowrap;
        padding: 15px 15px; }
  .drowl-headroom-wrapper .block {
    margin: 0; }

.webks-offcanvas-layer-opened button.webks-offcanvas-trigger .ico-menu::before {
  content: ""; }

.block-menu-menu-footer-menu .menu li {
  float: none;
  display: inline-block;
  margin-right: 15px; }
  .block-menu-menu-footer-menu .menu li:last-child {
    margin-right: 0; }
  .block-menu-menu-footer-menu .menu li a {
    padding: 2px 0; }

.sidebar .menu li a {
  border-bottom: 1px solid #d8d9da;
  padding: 0.85em 30px; }
  .sidebar .menu li a.active {
    font-weight: 700; }

.sidebar .menu li.expanded > .btn-expandable-menu-item {
  max-height: 52px;
  border-color: #d8d9da;
  border-width: 0 0 1px;
  border-style: solid; }
  .sidebar .menu li.expanded > .btn-expandable-menu-item::before {
    line-height: 52px; }

.sidebar .menu li.expanded.open > .btn-expandable-menu-item {
  border-color: #626366;
  background-color: #626366;
  color: #fff;
  border-width: 0 0 0 1px; }
  .sidebar .menu li.expanded.open > .btn-expandable-menu-item:hover {
    border-color: #6e7073;
    background-color: #6e7073;
    color: #fff; }
  .sidebar .menu li.expanded.open > .btn-expandable-menu-item:active, .sidebar .menu li.expanded.open > .btn-expandable-menu-item:focus {
    border-color: #565659;
    background-color: #565659;
    color: #fff; }

.sidebar .menu li.expanded.open > .menu {
  background: #626366;
  color: #fff; }
  .sidebar .menu li.expanded.open > .menu a {
    color: #fff;
    border-color: #3c3c3c; }
    .sidebar .menu li.expanded.open > .menu a:hover {
      background-color: #3c3c3c; }

button.webks-offcanvas-trigger {
  border-color: transparent;
  background-color: transparent;
  color: #626366;
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0; }
  button.webks-offcanvas-trigger:hover {
    border-color: transparent;
    background-color: transparent;
    color: #626366; }
  button.webks-offcanvas-trigger:active, button.webks-offcanvas-trigger:focus {
    border-color: transparent;
    background-color: transparent;
    color: #626366; }
  button.webks-offcanvas-trigger .ico-seperate {
    margin-right: 0;
    width: 32px; }
    button.webks-offcanvas-trigger .ico-seperate::before {
      font-size: 32px; }
  button.webks-offcanvas-trigger .btn-label {
    display: none; }

.webks-offcanvas-layer {
  display: none;
  position: fixed;
  right: 0;
  top: 69px;
  bottom: 0;
  z-index: 10000;
  max-height: none !important;
  width: 100%;
  max-width: 400px;
  background-color: #626366;
  overflow: auto;
  padding-bottom: 30px; }
  .webks-offcanvas-layer.open {
    display: block;
    animation-duration: 250ms;
    animation-fill-mode: both;
    animation-name: fadeInRight; }
  .webks-offcanvas-layer .btn-expandable-menu-item--closer {
    background-color: #4e4f51; }
  .webks-offcanvas-layer .menu {
    position: static !important; }
    .webks-offcanvas-layer .menu .menu {
      background-color: #4e4f51; }
      .webks-offcanvas-layer .menu .menu .btn-expandable-menu-item--closer {
        background-color: #444547; }
      .webks-offcanvas-layer .menu .menu .menu {
        background-color: #444547; }
        .webks-offcanvas-layer .menu .menu .menu .btn-expandable-menu-item--closer {
          background-color: #353637; }
        .webks-offcanvas-layer .menu .menu .menu .menu {
          background-color: #353637; }
  .webks-offcanvas-layer li.depth-0.expanded > a {
    padding-right: 28px; }
    .webks-offcanvas-layer li.depth-0.expanded > a::after {
      content: "";
      font-size: 12px;
      font-family: "iconfont";
      color: #d8d9da;
      float: right;
      margin-left: 15px;
      margin-top: 5px; }
  .webks-offcanvas-layer li.depth-0.expanded.open > a {
    border-bottom: 1px solid #565659; }
    .webks-offcanvas-layer li.depth-0.expanded.open > a::after {
      display: none; }
  .webks-offcanvas-layer li {
    border-bottom: 1px solid #565659;
    font-size: 16px;
    line-height: 20px; }
    .webks-offcanvas-layer li a {
      color: #fff;
      padding: 10px 20px; }
    .webks-offcanvas-layer li.expanded.open > a {
      border-bottom: 1px solid #565659; }
  .webks-offcanvas-layer .expandable-menu-item > a {
    margin-right: 40px; }
  .webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item {
    width: 40px;
    max-height: 40px; }
    .webks-offcanvas-layer .expandable-menu-item > .btn-expandable-menu-item::before {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 12px; }
  .webks-offcanvas-layer .menu-mlid-overview,
  .webks-offcanvas-layer .menu-mlid-close {
    display: none; }
  .webks-offcanvas-layer .block-search-form {
    border-bottom: 1px solid #565659;
    margin-bottom: 5px;
    padding: 10px 60px 10px 20px !important;
    float: none !important;
    width: 100% !important; }
    .webks-offcanvas-layer .block-search-form .form-submit {
      background-color: #565659 !important; }
    .webks-offcanvas-layer .block-search-form + .block-menu-block {
      padding-top: 0; }
  .webks-offcanvas-layer .block-menu-block {
    padding-top: 30px; }

button.drowl-offcanvas__close-btn {
  display: none; }

button.drowl-offcanvas__close-btn::before {
  font-size: 32px; }

@media (min-width: 768px) {
  .shrinked-main-menu-triggers li a {
    text-indent: 0; }
    .shrinked-main-menu-triggers li a::before {
      display: inline-block;
      position: static;
      margin-right: 5px;
      margin-bottom: -5px;
      width: auto; } }

.pager {
  clear: both;
  margin: 0;
  text-align: center;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  @media only screen and (min-width: 992px) {
    .pager {
      flex-wrap: nowrap; } }
  .pager .ajax-progress {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -8px;
    margin-top: -8px; }
  .pager li {
    margin: 0 5px 5px 0;
    display: inline-block;
    position: relative; }
    .pager li a {
      display: block; }
    .pager li:last-child {
      margin-right: 0; }
  .pager .pager-current {
    padding: 3px 5px; }
  .pager .pager-previous {
    margin-right: auto;
    flex-grow: 1; }
    @media only screen and (min-width: 992px) {
      .pager .pager-previous {
        flex-grow: 0; } }
  .pager .pager-next {
    margin-left: auto;
    flex-grow: 1; }
    @media only screen and (min-width: 992px) {
      .pager .pager-next {
        flex-grow: 0; } }
  .pager .pager-first,
  .pager .pager-last {
    flex-grow: 1; }
    @media only screen and (min-width: 992px) {
      .pager .pager-first,
      .pager .pager-last {
        flex-grow: 0; } }
  .pager a,
  .pager .pager-current {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: transparent;
    border-color: #626366;
    color: #626366;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em; }
    .pager a:hover,
    .pager .pager-current:hover {
      background-color: transparent;
      border-color: #76777b;
      color: #626366; }
    .pager a:active, .pager a:focus,
    .pager .pager-current:active,
    .pager .pager-current:focus {
      background-color: transparent;
      border-color: #565659;
      color: #626366; }
    .pager a:hover,
    .pager .pager-current:hover {
      text-decoration: none; }

.nav-tabs {
  position: relative; }

ul.primary {
  margin-bottom: 15px; }
  ul.primary a {
    text-decoration: none; }

#login-links,
.ui-tabs .ui-tabs-nav,
.horizontal-tabs-list {
  border-color: #e5e6e6;
  border-width: 0 0 1px;
  border-style: solid;
  border-radius: 0;
  position: relative;
  padding: 0;
  list-style: none;
  background: none;
  margin: .5em 0 1em;
  line-height: normal; }
  #login-links::after,
  .ui-tabs .ui-tabs-nav::after,
  .horizontal-tabs-list::after {
    content: ' ';
    width: 15px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 100%); }
  @media (max-width: 1169px) {
    #login-links,
    .ui-tabs .ui-tabs-nav,
    .horizontal-tabs-list {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden; } }
  #login-links li,
  .ui-tabs .ui-tabs-nav li,
  .horizontal-tabs-list li {
    border: 0 none;
    background: none;
    color: #626366;
    float: none;
    display: inline-block;
    margin-right: 3px; }
    #login-links li a.active,
    #login-links li a.lt-active,
    #login-links li.selected a,
    #login-links li.ui-tabs-active > a,
    .ui-tabs .ui-tabs-nav li a.active,
    .ui-tabs .ui-tabs-nav li a.lt-active,
    .ui-tabs .ui-tabs-nav li.selected a,
    .ui-tabs .ui-tabs-nav li.ui-tabs-active > a,
    .horizontal-tabs-list li a.active,
    .horizontal-tabs-list li a.lt-active,
    .horizontal-tabs-list li.selected a,
    .horizontal-tabs-list li.ui-tabs-active > a {
      position: relative;
      bottom: -1px;
      padding-top: 8px;
      color: #626366;
      background: #fff; }
    #login-links li a,
    .ui-tabs .ui-tabs-nav li a,
    .horizontal-tabs-list li a {
      display: block;
      padding: 7px 10px;
      text-decoration: none;
      outline: 0;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: #e5e6e6;
      border-radius: 0;
      background: #e5e6e6; }
      #login-links li a strong,
      .ui-tabs .ui-tabs-nav li a strong,
      .horizontal-tabs-list li a strong {
        font-weight: normal; }
      #login-links li a:hover, #login-links li a:focus,
      .ui-tabs .ui-tabs-nav li a:hover,
      .ui-tabs .ui-tabs-nav li a:focus,
      .horizontal-tabs-list li a:hover,
      .horizontal-tabs-list li a:focus {
        color: #626366;
        background: #fff; }
      #login-links li a:focus,
      .ui-tabs .ui-tabs-nav li a:focus,
      .horizontal-tabs-list li a:focus {
        outline: 0;
        border-color: #d0d1d3; }

.horizontal-tabs-panes {
  clear: both;
  padding: 10px 15px;
  background: #fff;
  border: 1px solid #d8d9da; }
  .horizontal-tabs-panes .field-group-htab {
    padding: 0; }
  .horizontal-tabs-panes .horizontal-tabs-pane > legend {
    display: none !important; }
  .horizontal-tabs-panes .horizontal-tabs-pane > .fieldset-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important; }
  .horizontal-tabs-panes .horizontal-tab-hidden {
    display: none; }

ul.secondary {
  margin: 0 0 8px;
  padding: .55em 10px;
  border-width: 0 1px 1px;
  border-style: solid;
  line-height: normal;
  border-color: #e5e6e6;
  background: #fff; }
  ul.secondary::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  ul.secondary li {
    border-color: #e5e6e6;
    float: left;
    margin-right: 7px;
    padding-right: 7px;
    list-style: none;
    border-width: 0 1px 0 0;
    border-style: solid; }
    ul.secondary li:last-child {
      border-width: 0; }
    ul.secondary li a {
      display: block;
      text-decoration: none; }

div.vertical-tabs {
  margin: 1em 0;
  width: 100%;
  border: 0 none;
  box-sizing: border-box; }
  div.vertical-tabs ul.vertical-tabs-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-width: 0 3px 0 0;
    border-style: solid;
    border-color: #626366;
    box-sizing: border-box; }
    @media (min-width: 768px) {
      div.vertical-tabs ul.vertical-tabs-list {
        width: 30%;
        float: left; } }
    div.vertical-tabs ul.vertical-tabs-list li {
      border: 0 none;
      margin-bottom: 2px; }
      div.vertical-tabs ul.vertical-tabs-list li a {
        color: #626366;
        text-decoration: none;
        display: block;
        padding: 10px;
        line-height: 1.1em;
        border-width: 1px 0 1px 1px;
        border-style: solid;
        border-color: #fff;
        background-color: #fff; }
        div.vertical-tabs ul.vertical-tabs-list li a .form-required {
          color: #fff; }
        div.vertical-tabs ul.vertical-tabs-list li a strong {
          font-weight: normal; }
        div.vertical-tabs ul.vertical-tabs-list li a .summary {
          display: block;
          color: #d8d9da; }
        div.vertical-tabs ul.vertical-tabs-list li a:hover {
          outline: 0; }
      div.vertical-tabs ul.vertical-tabs-list li:hover {
        border-color: #e5e6e6;
        background: #fff; }
      div.vertical-tabs ul.vertical-tabs-list li.selected a {
        background: #626366;
        border-color: #626366;
        border-radius: 8px 0 0 8px; }
        div.vertical-tabs ul.vertical-tabs-list li.selected a .summary {
          color: #bcbdbf; }
      div.vertical-tabs ul.vertical-tabs-list li.selected strong {
        color: #fff; }
  div.vertical-tabs .vertical-tabs-panes {
    clear: both;
    border-width: 0 0 3px;
    border-style: solid;
    border-color: #626366;
    margin-left: -3px;
    box-sizing: border-box; }
    @media (min-width: 768px) {
      div.vertical-tabs .vertical-tabs-panes {
        clear: none;
        float: left;
        width: 70%;
        border-width: 0 0 0 3px; } }
  div.vertical-tabs .vertical-tabs-pane {
    margin: 0;
    border: 0 none;
    box-sizing: border-box; }
    div.vertical-tabs .vertical-tabs-pane > legend {
      display: none; }

ul.action-links {
  margin: 10px 0 25px;
  padding: 0;
  list-style: none; }
  ul.action-links > li > a {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    background-color: transparent;
    border-color: #626366;
    color: #626366;
    padding: 15px 20px;
    border-radius: 0;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em; }
    ul.action-links > li > a:hover {
      background-color: transparent;
      border-color: #76777b;
      color: #626366; }
    ul.action-links > li > a:active, ul.action-links > li > a:focus {
      background-color: transparent;
      border-color: #565659;
      color: #626366; }
    ul.action-links > li > a:hover {
      text-decoration: none; }

.ui-tabs {
  border: 0 none; }
